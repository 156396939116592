import React from 'react';
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Title } from "../components/Globals"
import imageindia from '../images/case-studies/salesforce-essential-case-study-cymetrix-india.jpg';
import csBorder from './../images/case-studies/cs-border.jpg';
import { Link } from 'gatsby';

const CaseStudiesPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ minHeight: '10vh'}} />
    <Title title="Case Studies"/>
    <section className="mt-16">
        <div className="flex flex-wrap -mx-2 sma:mx-2 sm:mx-10 lg:mx-20 xl:ml-64 xl:mr-64 md:mt-10 md:mb-10">
            <div className="ml-auto">
               <img src={imageindia} />
            </div>

            <div className="mr-auto ml-6 mt-2">
                <div className="col-xs-12 col-md-4">
                    <div className="castud_desc">
                        <img src={csBorder}/>
                        <div className="text-2xl text-gray-700 my-8 leading-snug">
                            Solar EPC <br/> Insolergy <br/> empowers its <br/> growing team for <br/> a
                            sustainable <br/> world
                        </div>
                        <div className="bg-transparent hover:border-blue-500 text-gray-700 text-base font-thin w-48 py-2 px-4 border border-yellow-500 hover:border-transparent rounded mb-8">
                            <Link target="_blank"
                               to="https://blogs.cymetrixsoft.com/a-leading-solar-energy-company-drives-new-levels-of-productivity-with-salesforce-essentials/">READ CASE STUDY</Link>
                        </div>
                        <img src={csBorder}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="flex flex-wrap -mx-2 sma:mx-2 sm:mx-10 lg:mx-20 xl:ml-64 xl:mr-64 md:mt-10 md:mb-10">
            <div className="col-md-4 col-sm-6">
                <div className="csBlock">
                    <img src={csBorder}/>
                        <div>
                            <h4><b>French Pharma Company</b></h4>
                            <p align="center"></p>
                            <ul>
                                <li><strong>Platform used:</strong> Sales force CRM, Apex, Visual Force, Force.com IDE
                                </li>
                                <br />
                                <li><strong>Description and deliverable:</strong> Custom SFDC application developed
                                    gives customer care executive with a single view of customer to enable him/her
                                    to provide services related to customer. SFDC platform was integrated with
                                    backend systems of company to get client transactional data.
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
        </div>
    </section>
  </Layout>
)

export default CaseStudiesPage;